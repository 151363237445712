<script setup>

import {onMounted, ref, watch} from "vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';
import moment from "moment/moment";

const currency = import.meta.env.VITE_LOCALIZATION_COUNTRY_CURRENCY_SIGN;
const props = defineProps(['total', 'totalPaid', 'totalUnpaid', 'totalOverdue']);
const orders = ref({});
const errors = ref({});
const searchOrder = ref('');
const filterOrder = ref('');
const selectedOrder = ref({});

$(document).ready(function() {
    $(".data-attributes span").peity("donut")
});

function filterOrders()
{
    let urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('filter')){
        urlParams.set('filter', filterOrder.value);
    }else{
        urlParams.append('filter', filterOrder.value);
    }
    location.search = urlParams.toString();
}

const getOrders = async (page = 1) => {
    await axios.get(`/apis/v4/saas/orders?page=${page}&filter=${filterOrder.value}&keyword=${searchOrder.value}`).then(resp => {
        orders.value = resp.data.data;
    }).catch(err => {
        errors.value = err.response.data.errors;
    });
}

function setSearchFilterOrder() {
    let urlParams = new URLSearchParams(window.location.search);
    searchOrder.value = urlParams.has('search') ? urlParams.get('search') : '';
    filterOrder.value = urlParams.has('filter') ? urlParams.get('filter') : '';
}

function formatDate(value, format = 'DD/MM/YYYY HH:mm:ss') {
    return moment(value).format(format);
}

function populateOrder(order) {
    if (order) {
        selectedOrder.value = order;
    }
}

onMounted(function () {
    setSearchFilterOrder();
    getOrders();
});

</script>

<template>
    <div>
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col">
                            <div
                                class="d-flex flex-column align-items-start justify-content-around border p-3 rounded-4 gap-4">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="">
                                        <p class="mb-0 data-attributes">
                                            <span data-peity='{ "fill": ["#18A00D", "#eee"], "innerRadius": 32, "radius": 40 }'>
                                                {{ totalPaid }}/{{ total }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 fs-6">Total Paid</p>
                                        <h3 class="mb-0">{{ totalPaid }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div
                                class="d-flex flex-column align-items-start justify-content-around border p-3 rounded-4 gap-4">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="">
                                        <p class="mb-0 data-attributes">
                                            <span data-peity='{ "fill": ["orange", "#eee"], "innerRadius": 32, "radius": 40 }'>
                                                {{ totalUnpaid }}/{{ total }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 fs-6">Total Unpaid</p>
                                        <h3 class="mb-0">{{ totalUnpaid }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div
                                class="d-flex flex-column align-items-start justify-content-around border p-3 rounded-4 gap-4">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="">
                                        <p class="mb-0 data-attributes">
                                            <span data-peity='{ "fill": ["red", "#eee"], "innerRadius": 32, "radius": 40 }'>
                                                {{ totalOverdue }}/{{ total }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="">
                                        <p class="mb-1 fs-6">Total Overdue</p>
                                        <h3 class="mb-0">{{ totalOverdue }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6">
                                <form action="/four/admin/finance/orders" method="GET">
                                    <input type="hidden" name="filter" :value="filterOrder"/>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control"
                                               placeholder="Search by order number, buyer name or product"
                                               name="search"
                                               id="searchOrder"
                                               :value="searchOrder"
                                               aria-label="Search Orders" aria-describedby="button-addon2">
                                        <button class="btn btn-outline-secondary" type="submit" id="performSearchOrder">
                                            Search
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-6">
                                <div class="col-md-4" style="float: right">
                                    <select class="form-select" v-model="filterOrder" @change="filterOrders">
                                        <option value="">-- Filter --</option>
                                        <option value="paid">Paid</option>
                                        <option value="active">Pending Payment</option>
                                        <option value="overdue">Overdue</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead v-if="orders && orders.data && orders.data.length > 0">
                            <tr>
                                <th>#</th>
                                <th>Order #</th>
                                <th>Buyer</th>
                                <th>Seller</th>
                                <th>Product</th>
                                <th>Total</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody v-if="orders && orders.data && orders.data.length > 0">
                            <tr v-for="(order,index) in orders.data" :key="order.id">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>{{ order.order_id }}</td>
                                <td>{{ order.buyer.phone }}</td>
                                <td>{{ order.seller.phone }}</td>
                                <td>{{ order.summary_line }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span class="badge bg-dark">{{ currency }}{{ order.total.toLocaleString() }}</span>
                                    </div>
                                </td>
                                <td>{{ formatDate(order.created_at, 'DD/MM/YYYY') }}</td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <span v-if="order.order_status.class === 'info'" class="badge bg-info text-dark">
                                            {{ order.order_status.name }}
                                        </span>
                                        <span v-else-if="order.order_status.class === 'warning'" class="badge bg-warning text-dark">
                                            {{ order.order_status.name }}
                                        </span>
                                        <span v-else-if="order.order_status.class === 'success'" class="badge bg-success">
                                            {{ order.order_status.name }}
                                        </span>
                                        <span v-else-if="order.order_status.class === 'danger'" class="badge bg-danger">
                                            {{ order.order_status.name }}
                                        </span>
                                        <span v-else-if="order.order_status.class === 'default'" class="badge bg-secondary">
                                            {{ order.order_status.name }}
                                        </span>
                                        <span v-else class="badge bg-dark">
                                            {{ order.order_status.name }}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-outline-primary"
                                            @click="populateOrder(order)"
                                            data-bs-toggle="offcanvas" href="#viewOrder" role="button"
                                            aria-controls="viewOrder"
                                    >
                                        View Order <i class="fa fa-angle-double-right fa-thin"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td align="center" colspan="7">No record found.</td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="col-12">
                            <bootstrap5-pagination :data="orders" @pagination-change-page="getOrders" style="overflow: scroll"/>
                        </div>
                    </div>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="viewOrder"
                         aria-labelledby="viewOrderLabel">
                        <div class="offcanvas-header">
                            <h5 class="offcanvas-title" id="viewOrderLabel">Order Details</h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td>Order Number:</td>
                                        <td><b>{{ selectedOrder?.order_id }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Product:</td>
                                        <td><b>{{ selectedOrder?.summary_line }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Name:</td>
                                        <td><b>{{ selectedOrder?.buyer?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Phone:</td>
                                        <td><b>{{ selectedOrder?.buyer?.phone }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Buyer Market:</td>
                                        <td><b>{{ selectedOrder?.buyer_group?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Name:</td>
                                        <td><b>{{ selectedOrder?.seller?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Phone:</td>
                                        <td><b>{{ selectedOrder?.seller?.phone }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Market:</td>
                                        <td><b>{{ selectedOrder?.seller_group?.name }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Bank:</td>
                                        <td><b>{{ selectedOrder?.seller_bank?.name ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Seller Account Number:</td>
                                        <td><b>{{ selectedOrder?.seller?.account_number ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Agent Name:</td>
                                        <td><b>{{ selectedOrder?.agent_user?.name ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Agent Phone:</td>
                                        <td><b>{{ selectedOrder?.agent_user?.phone ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Sales Officer Name:</td>
                                        <td><b>{{ selectedOrder?.sales_officer?.name ?? '---' }}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Total Order Value:</td>
                                        <td><span class="badge bg-dark">{{ currency }}{{ selectedOrder?.total?.toLocaleString() }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Total Product Value:</td>
                                        <td><span class="badge bg-dark">{{ currency }}{{ selectedOrder?.items_price?.toLocaleString() }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Fees Applied:</td>
                                        <td><span class="badge bg-dark">{{ currency }}{{ selectedOrder?.fees?.toLocaleString() }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Charges:</td>
                                        <td><span class="badge bg-dark">{{ currency }}{{ selectedOrder?.charges?.toLocaleString() }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Agent Commission:</td>
                                        <td>
                                            <span class="badge bg-dark" v-if="selectedOrder?.agent_fee">
                                                {{ currency }}{{ selectedOrder?.agent_fee?.toLocaleString() }}
                                            </span>
                                            <b v-else>---</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Contribution:</td>
                                        <td><span class="badge bg-dark">{{ currency }}{{ selectedOrder?.insurance_contribution?.toLocaleString() }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Penalty Total Repayment:</td>
                                        <td><span class="badge bg-dark">+{{ currency }}{{ selectedOrder?.bnpl_fees_incurred?.toLocaleString() }}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>
                                            <span v-if="selectedOrder.order_status?.class === 'info'" class="badge bg-info text-dark">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                                <span v-else-if="selectedOrder.order_status?.class === 'warning'" class="badge bg-warning text-dark">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                                <span v-else-if="selectedOrder.order_status?.class === 'success'" class="badge bg-success">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                                <span v-else-if="selectedOrder.order_status?.class === 'danger'" class="badge bg-danger">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                                <span v-else-if="selectedOrder.order_status?.class === 'default'" class="badge bg-secondary">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                                <span v-else class="badge bg-dark">
                                                {{ selectedOrder.order_status?.name }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Order Datetime:</td>
                                        <td><b>{{ formatDate(selectedOrder?.created_at) }}</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.page-link {
    color: #00ab4d;
}
.page-link:hover {
    color: #00ab4d;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: #00ab4d;
    border-color: #00ab4d;
}
</style>
